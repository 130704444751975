<template>
  <header class="topbar">
    <b-navbar toggleable="lg" :type="`${navbarColor == 'primary' ? 'light' : 'dark'}`" class="p-0"
      :variant="navbarColor">
      <div class="container-fluid">
        <b-navbar-nav>
          <b-nav-item @click="showMobileSidebar" class="d-block d-lg-none"><i class="mdi mdi-menu fs-3"></i>
          </b-nav-item>
        </b-navbar-nav>
        <div class="mx-2 logo-top">
          <LogoDark v-if="navbarColor == 'primary'" />
          <LogoLight v-else />

          <span v-if="verificarEnv()" class="badge bg-danger homolog-alert"> {{ $t("GERAL.HOMOLOG") }}</span>

        </div>
        <b-navbar-toggle target="nav-collapse" class="mx-2"><i class="ti-more"></i></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav class="">
          <!-- Right aligned nav items -->

          <b-navbar-nav class="ml-auto align-auto">            
            <botao-perfil/>            
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    
  </header>
</template>

<script>
import { mapState } from "vuex";
import LogoDark from "../logo-dark/LogoDark";
import LogoLight from "../logo-light/LogoLight";
import { DEFINIR_IDIOMA } from "@/store/idioma.module";
import JwtService from "@/common/utils/jwt";
import { START_LOADING } from "@/store/Store";
import BotaoPerfil from "./components/BotaoPerfil";
export default {
  name: "Horizontal-header",
  data: () => ({
    showToggle: false,
  }),
  components: {
    LogoDark,
    LogoLight,
    BotaoPerfil
  },
  computed: {
    ...mapState(["navbarColor", "logoColor", "LayoutType"]),
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == "en") return { lang: "flag-icon flag-icon-us" };
      else if (locale == "br") return { lang: "flag-icon flag-icon-br" };
      return this.locale;
    },
    nomeUsuario() {
      return `${JwtService.buscarNome()}`;
    },
    email() {
      return JwtService.buscarEmail();
    },
    usuarioId(){
      return JwtService.buscarId();
    },
  },
  methods: {
    verificarEnv(){
      return process.env.VUE_APP_API.indexOf('homolog') > -1;
    },
    showMobileSidebar() {
      this.$store.commit("SET_SIDEBAR_DRAWER", true);
    },
    setMiniSidebar() {
      if (this.LayoutType == "full-sidebar") {
        this.$store.dispatch("setLayoutType", "mini-sidebar");
      } else if (this.LayoutType == "mini-sidebar") {
        this.$store.dispatch("setLayoutType", "full-sidebar");
      }
    },
    alterarIdioma(idioma) {
      this.$store.dispatch(START_LOADING);
      this.$store.dispatch(DEFINIR_IDIOMA, idioma).then(() => {
        location.reload();
      });
      this.$i18n.locale = idioma;
    },
    
  },
};
</script>

<style>


  .homolog-alert{
    color: white!important;
    margin-left: 10px;
  }

  .logo-top{
    display: flex;
    align-items: center;
  }

  .perguntas {
      color: white;
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 47px;
  }

  .align-auto{
    display: flexa;
    align-items: center;
  }

  .perguntas:hover{
    color: #fd7e14
  }

  .perguntas i {
    margin: auto;
  }

  .faq{
    font-size: 0.875em;
  }
</style>