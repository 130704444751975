<template>
  <b-modal
    :title="$t('GERAL.TROCAR_SENHA')"
    header-bg-variant="light"
    ref="modal-login"
    size="md"
    hide-footer
    @hidden="fecharModal()"
  >
    <b-card-text>
      

      <input-text
        ref="novaSenha"
        v-model="form.novaSenha"
        noLabel
        type="password"
        :label="$t('GERAL.NOVA_SENHA')"
        :placeholder="$t('GERAL.NOVA_SENHA')"
        :minLength="6"
        required
        @input="verificarForcaDaSenha"
      />
      <verifica-forca-senha embaixo :value="forcaSenha" class="mb-2"></verifica-forca-senha>

      <input-text
        ref="novaConfirmacaoSenha"
        v-model="form.novaConfirmacaoSenha"
        noLabel
        type="password"
        :label="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
        :placeholder="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
        :validate="validarSenha"
        :minLength="6"
        required
      />

      <b-button
        variant="primary"
        size="lg"
        block
        class="mt-4"
        @click="alterarSenha"
        >{{ $t("GERAL.TROCAR_SENHA") }}</b-button
      >
    </b-card-text>
  </b-modal>
</template>
<script>
import VerificaForcaSenha from "@/components/outros/VerificaForcaSenha.vue";
import InputText from "@/components/inputs/InputText";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";
import UsuarioService from "@/common/services/usuario/usuario.service";
import {AlertasMixin} from "../../common/mixins/AlertasMixin";
export default {
  name: "ModalAlteraSenha",
  mixins : [AlertasMixin],
  data() {
    return {
      form: {
        novaSenha: null,
        novaConfirmacaoSenha: null,
        forcaSenha : "",
      },
    };
  },
  components: {
    InputText,
    VerificaForcaSenha
  },
  props: {
    mostrarModal: { type: Boolean, default: false },
  },
  watch: {
    mostrarModal(valor) {
      if (valor) this.$refs["modal-login"].show();
      else this.$refs["modal-login"].hide();
    },
  },
  computed: {
    validarSenha() {
      return this.form.novaSenha === this.form.novaConfirmacaoSenha;
    },
  },
  methods: {
    fecharModal() {
      this.$emit("fecharModal");
    },
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.novaSenha.valid());
      arValidation.push(this.$refs.novaConfirmacaoSenha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    alterarSenha() {
      if (!this.validarFormulario()) return;
      if(this.form.novaSenha != this.form.novaConfirmacaoSenha){
        this.alertaErro(this.$("USUARIOS.MENSAGEM_SENHA"));
        return;
      }

      this.$store.dispatch(START_LOADING);
      
      this.form.usuarioId = localStorage.getItem('usuario_id');

      MinhaContaService.alterarSenha(this.form)
      .then(() => {
        this.alertaSucesso(this.$t('USUARIOS.MENSAGEM_SUCESSO_SENHA'))
        this.fecharModal();
      }).finally(() => {
        this.$store.dispatch(STOP_LOADING);
      });
    },
    verificarForcaDaSenha() {
      let novaSenha = this.form.novaSenha;
      UsuarioService.verificarForcaDaSenha(novaSenha).then((result) => {
          this.forcaSenha = result.data.forcaSenhaEnum;
      })
    },
  },
};
</script>
